module.exports = {
  appNames: {
    training: {
      source: 'professional',
      value: 'LearnerZone'
    },
    admin: {
      source: 'professional',
      value: 'AdminZone'
    },
    manager: {
      source: 'professional',
      value: 'LeaderZone'
    },
    knowledge: {
      source: 'professional',
      value: 'Discover'
    },
    integration: {
      source: 'professional',
      value: 'Integration'
    },
    editor: {
      source: 'professional',
      value: 'Editor'
    }
  },
  contentExchange: {
    source: 'professional',
    value: 'Content Marketplace'
  },
  genericNames: {
    embeddedVideoProviders: {
      VIMEO: {
        source: 'professional',
        value: 'Vimeo'
      },
      YOUTUBE: {
        source: 'professional',
        value: 'YouTube'
      }
    },
  },
  nativeLanguageNames: {
    AR: {
      source: 'professional',
      value: 'العَرَبِيةُ'
    },
    BE: {
      source: 'professional',
      value: 'беларускі'
    },
    BG: {
      source: 'professional',
      value: 'български'
    },
    BN: {
      source: 'professional',
      value: 'বাংলা'
    },
    BS: {
      source: 'professional',
      value: 'Bosanski'
    },
    CS: {
      source: 'professional',
      value: 'Čeština'
    },
    DA: {
      source: 'professional',
      value: 'Dansk'
    },
    DE: {
      source: 'professional',
      value: 'Deutsch'
    },
    EL: {
      source: 'professional',
      value: 'Ελληνικά'
    },
    EN: {
      source: 'professional',
      value: 'English'
    },
    'EN-AU': {
      source: 'professional',
      value: 'English (Australia)'
    },
    'EN-GB': {
      source: 'professional',
      value: 'English (UK)'
    },
    ES: {
      source: 'professional',
      value: 'Español (Norteamérica)'
    },
    'ES-ES': {
      source: 'professional',
      value: 'Español (España)'
    },
    ET: {
      source: 'professional',
      value: 'eesti'
    },
    FA: {
      source: 'professional',
      value: 'فارسی'
    },
    FI: {
      source: 'professional',
      value: 'suomi'
    },
    FL: {
      source: 'professional',
      value: 'Filipino'
    },
    FR: {
      source: 'professional',
      value: 'Français (Canada)'
    },
    'FR-FR': {
      source: 'professional',
      value: 'Français (France)'
    },
    HE: {
      source: 'professional',
      value: 'עִברִית'
    },
    HI: {
      source: 'professional',
      value: 'हिन्दी'
    },
    HR: {
      source: 'professional',
      value: 'hrvatski'
    },
    HT: {
      source: 'professional',
      value: 'Kreyòl ayisyen'
    },
    HU: {
      source: 'professional',
      value: 'magyar'
    },
    HY: {
      source: 'professional',
      value: 'Հայերեն'
    },
    ID: {
      source: 'professional',
      value: 'Indonesia'
    },
    IT: {
      source: 'professional',
      value: 'Italiano'
    },
    JA: {
      source: 'professional',
      value: '日本語'
    },
    KM: {
      source: 'professional',
      value: 'ភាសាខ្មែរ'
    },
    KO: {
      source: 'professional',
      value: '한국어'
    },
    LO: {
      source: 'professional',
      value: 'ພາສາລາວ'
    },
    LT: {
      source: 'professional',
      value: 'Lietuviškai'
    },
    LV: {
      source: 'professional',
      value: 'Latviešu'
    },
    MK: {
      source: 'professional',
      value: 'македонски'
    },
    ML: {
      source: 'professional',
      value: 'മലയാളം'
    },
    MS: {
      source: 'professional',
      value: 'Malay'
    },
    MY: {
      source: 'professional',
      value: 'မြန်မာဘာသာ'
    },
    'MY-HA': {
      source: 'professional',
      value: 'Laiholh'
    },
    NE: {
      source: 'professional',
      value: 'नेपाली'
    },
    NL: {
      source: 'professional',
      value: 'Nederlands'
    },
    NO: {
      source: 'professional',
      value: 'Norsk'
    },
    PA: {
      source: 'professional',
      value: 'ਪੰਜਾਬੀ (ਗੁਰਮੁਖੀ)'
    },
    PL: {
      source: 'professional',
      value: 'Polski'
    },
    PT: {
      source: 'professional',
      value: 'Português (Brasil)'
    },
    'PT-PT': {
      source: 'professional',
      value: 'Português (Portugal)'
    },
    'RO-MD': {
      source: 'professional',
      value: 'Moldovenească'
    },
    RO: {
      source: 'professional',
      value: 'Română'
    },
    RU: {
      source: 'professional',
      value: 'Pусский'
    },
    SK: {
      source: 'professional',
      value: 'Slovensky'
    },
    SL: {
      source: 'professional',
      value: 'Slovenščina'
    },
    SO: {
      source: 'professional',
      value: 'Soomaali'
    },
    SQ: {
      source: 'professional',
      value: 'Shqiptar'
    },
    SR: {
      source: 'professional',
      value: 'Српски (ћирилица)'
    },
    'SR-LT': {
      source: 'professional',
      value: 'Srpski (latinica)'
    },
    SV: {
      source: 'professional',
      value: 'Svenska'
    },
    SW: {
      source: 'professional',
      value: 'Kiswahili'
    },
    TA: {
      source: 'professional',
      value: 'தமிழ்'
    },
    TE: {
      source: 'professional',
      value: 'తెలుగు'
    },
    TH: {
      source: 'professional',
      value: 'ภาษาไทย'
    },
    TR: {
      source: 'professional',
      value: 'Türkçe'
    },
    UK: {
      source: 'professional',
      value: 'Українська'
    },
    UR: {
      source: 'professional',
      value: 'اردو'
    },
    VI: {
      source: 'professional',
      value: 'tiếng Việt'
    },
    YO: {
      source: 'professional',
      value: 'Yorùbá'
    },
    ZH: {
      source: 'professional',
      value: '简体中文'
    },
    'ZH-HK': {
      source: 'professional',
      value: '繁體中文 (香港)'
    },
    'ZH-TW': {
      source: 'professional',
      value: '繁體中文 (台灣)'
    }
  },
  logErrors: {
    400: {
      source: 'professional',
      value: '400 Error, code: <%- errCode %>.'
    },
    9021: {
      source: 'professional',
      value: 'Unauthorized IP error.'
    },
    genericError: {
      source: 'professional',
      value: 'Generic error!'
    },
    serverError: {
      source: 'professional',
      value: 'Server Error: <%- status %>!'
    },
    noConnection: {
      source: 'professional',
      value: 'No connection detected error.'
    },
    timeout: {
      source: 'professional',
      value: 'Request timeout error.'
    },
    unhandled400: {
      source: 'professional',
      value: '400 Error, unhandled code: <%- errCode %>.'
    }
  },
  OAuthTypes: {
    oauth2: {
      source: 'professional',
      value: 'OAuth2'
    },
    openIdConnect: {
      source: 'professional',
      value: 'OpenID Connect'
    }
  },
  OAuthProviders: {
    facebook: {
      source: 'professional',
      value: 'Facebook'
    },
    google: {
      source: 'professional',
      value: 'Google'
    },
    linkedin: {
      source: 'professional',
      value: 'LinkedIn'
    },
    salesforce: {
      source: 'professional',
      value: 'Salesforce'
    },
    zendesk: {
      source: 'professional',
      value: 'Zendesk'
    }
  },
  formLabels: {
    OAuthConfigModel: {
      jwksUri: {
        source: 'professional',
        value: 'JWKS Uri'
      }
    }
  }
};
